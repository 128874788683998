<template>
  <div class="user-assignment-card p-4">
    <div class="user-assignment-card-header">
      <div>
        <div><i class="icon-user"></i> {{ fileItem.fullname }}</div>
        <!-- <p>
          <a :href="`mailto:${fileItem.email}`" class="email-link">{{
            fileItem.email
          }}</a>
          -
          <a :href="`tel:${fileItem.phone}`" class="tel-link">
            {{ fileItem.phone }}</a
          >
        </p> -->
      </div>
      <div class="text-center mt-3" v-if="fileItem.assignments.length === 0">
        <small> {{ $t("noFoundUserAssignment") }}</small>
      </div>
      <div class="px-md-4 mt-3" v-else>
        <div v-for="(file, i) in fileItem.assignments" :key="i">
          <hr />
          <p>
            <strong>{{ i + 1 }}.</strong>
            <a
              target="_blank"
              :href="$api.image(file.filePath)"
              class="email-link"
            >
              <button class="btn-xs mx-2 btn-pill btn-dark btn">
                {{ $t("viewFile") }}
              </button>
            </a>
            <a
              target="_blank"
              :href="$api.image(file.filePath)"
              class="email-link"
            >
              {{ formatter(file.createdAt) }}</a
            >
            <strong class="mx-3">Puan: </strong>
            <input
              type="number"
              style="width: 50px"
              :min="0"
              :max="100"
              v-model="file.score"
              class="mr-3"
            />
            <button
              v-if="file.score >= 0 && file.score <= 100"
              @click="SAVE_FILE_SCORE(file)"
              class="btn-xs btn-pill donate btn"
            >
              {{ $t("save") }}
            </button>
          </p>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import AlertBox from "../../mixins/AlertBox";
import { formatter } from "gurhan/helpers/Date";
export default {
  mixins: [AlertBox],
  props: {
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      fileItem: {},
    };
  },
  methods: {
    formatter,
    async SAVE_FILE_SCORE(file) {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.put(`UserAssignments/${file.id}`, {
        score: file.score,
      });
      if (response.message === "OK")
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
      else this.SET_ALERT_BOX("success", this.$t("updatedIsNotSucceed"));
      // this.SET_LOADING_MODAL(false);
    },
  },
  mounted() {
    this.fileItem = this.item;
  },
};
</script>

<style></style>
