var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-lg-4"},[_c('p',[_vm._v(_vm._s(_vm.$t("startDate")))]),_c('h5',[_vm._v(_vm._s(_vm.formatter(new Date(_vm.quiz.startDate))))]),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("finishDate")))]),_c('h5',[_vm._v(_vm._s(_vm.formatter(new Date(_vm.quiz.finishDate))))]),_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("situation")))]),_c('h5',{class:_vm.quiz.isFinish === 1 ? 'text-success' : 'text-danger'},[_vm._v(" "+_vm._s(_vm.quiz.isFinish === 1 ? _vm.$t("done") : _vm.$t("undone"))+" ")])]),_c('div',{staticClass:"col-6 col-lg-2"},[_c('div',{staticClass:"card mr-2"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('h1',[_vm._v(_vm._s(_vm.quiz.questionTotalCount))]),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("totalQuestion")))])])])])]),_c('div',{staticClass:"col-6 col-lg-2"},[_c('div',{staticClass:"card mr-2"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('h1',[_vm._v(_vm._s(_vm.quiz.correctAnswerTotalCount))]),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("correctQuestion")))])])])])]),_c('div',{staticClass:"col-6 mt-2 mt-lg-0 col-lg-2"},[_c('div',{staticClass:"card mr-2"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('h1',{class:`${
                _vm.percentOfCorrectAnswer >= 75
                  ? 'text-success'
                  : _vm.percentOfCorrectAnswer >= 50
                  ? 'text-warning'
                  : 'text-danger'
              }`},[_vm._v(" "+_vm._s(_vm.percentOfCorrectAnswer)+"% ")]),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("percentOfCorrentAnswer")))])])])])]),_c('div',{staticClass:"col-6 col-lg-2"},[_c('button',{staticClass:"btn w-100 h-100 btn-text p-0 m-0",on:{"click":function($event){return _vm.SHOW_ANSWERS()}}},[_c('div',{staticClass:"card mr-2 bg-warning"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_vm._m(0),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("viewAnswers")))])])])])])])]),(_vm.showAnswers)?_c('div',{staticClass:"mt-4"},[(_vm.isLoading)?_c('page-loading'):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('h6',[_vm._v(_vm._s(_vm.$t("questions")))]),_vm._l((_vm.questionList),function(question,i){return _c('div',{key:i,staticClass:"card p-2 my-1"},[_c('button',{staticClass:"btn text-dark btn-text",on:{"click":function($event){_vm.activeQuestion = question}}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center px-2"},[_c('span',[_vm._v(" "+_vm._s(question.questionText))]),_c('img',{attrs:{"src":"/images/right-arrow.png","width":"20","alt":""}})])])])})],2),(_vm.activeQuestion !== null)?_c('div',{staticClass:"col-12 col-lg-6"},[_c('h6',[_vm._v(_vm._s(_vm.$t("question")))]),_c('div',{staticClass:"card p-3 my-1"},[_c('h6',[_vm._v(_vm._s(_vm.activeQuestion.questionText))])]),_c('div',{staticClass:"mt-3"},[_c('h6',[_vm._v(_vm._s(_vm.$t("answers")))]),_vm._l((_vm.activeQuestion.quizQuestionAnswers),function(answer,i){return _c('div',{key:i,staticClass:"card p-3 my-1",class:{
                'bg-success text-white':
                  answer.isAnswered === 1 &&
                  answer.quizQuestionAnswerId === 1,
                'bg-danger text-white':
                  answer.isAnswered === 1 &&
                  answer.quizQuestionAnswerId !== 1,
                'bg-success text-white':
                  answer.isAnswered === 0 &&
                  answer.quizQuestionAnswerId === 1,
              }},[_vm._v(" "+_vm._s(answer.answerText)+" ")])})],2)]):_vm._e()])],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('i',{staticClass:"icon-search"})])
}]

export { render, staticRenderFns }