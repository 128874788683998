<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-6">
          <h3>
            {{ quiz.quizInfo.name }}
            <small
              class="badge"
              :class="`badge-${
                quiz.quizInfo.isActive === 1 ? 'success' : 'danger'
              }`"
              >{{
                quiz.quizInfo.isActive === 1 ? $t("active") : $t("closed")
              }}</small
            >
          </h3>
          <p class="text-muted mt-2">{{ quiz.quizInfo.description }}</p>
          <p class="text-muted">
            {{ $t("duration") }}: {{ quiz.quizInfo.duration }}dk.
          </p>
          <div class="mt-2">
            <button
              :title="$t('update')"
              class="btn m-1 btn-pill btn-xs btn-warning mx-1"
              @click="SHOW_QUIZ_DETAIL(quiz.quizInfo)"
            >
              <img src="/images/edit.svg" width="20" alt="" />
              {{ $t("update") }}
            </button>
            <button
              :title="$t('remove')"
              @click="
                removeQuiz = { status: true, index: 0, id: quiz.quizInfo.id }
              "
              class="btn m-1 btn-pill btn-xs btn-danger mx-1 btn-icon"
            >
              <img src="/images/trush.svg" width="20" alt="" />
              {{ $t("remove") }}
            </button>
            -
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <div class="card mr-2">
            <div
              class="card-body d-flex align-items-center justify-content-center flex-column"
            >
              <h1>{{ quiz.solverTotalCount }}</h1>
              <div class="mt-1">
                <small class="text-muted">{{ $t("solverQuiz") }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <div class="card mr-2">
            <div
              class="card-body d-flex align-items-center justify-content-center flex-column"
            >
              <h1>{{ quiz.solvedTotalCount }}</h1>
              <div class="mt-1">
                <small class="text-muted">{{ $t("solvedQuiz") }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2 mt-lg-0 col-lg-2">
          <div class="card mr-2">
            <div
              class="card-body d-flex align-items-center justify-content-center flex-column"
            >
              <h1
                :class="`${
                  percentOfCorrectAnswer >= 75
                    ? 'text-success'
                    : percentOfCorrectAnswer >= 50
                    ? 'text-warning'
                    : 'text-danger'
                }`"
              >
                {{ percentOfCorrectAnswer }}%
              </h1>
              <div class="mt-1">
                <small class="text-muted">{{
                  $t("percentOfCorrentAnswer")
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal hide-footer v-model="showEditQuizModal">
      <QuizModal
        :product="product"
        edit
        :key="editModalKey"
        :event="event"
        :video="video"
        :type="fileType"
        :item="selectedQuiz"
        @close="showEditQuizModal = false"
        @refresh="$emit('refresh')"
      ></QuizModal>
    </Modal>
    <Modal
      @ok="
        SET_REMOVE_FILE(
          `/video-detail/${$route.params.sectionId}/${$route.params.videoId}?tab=Quizes`
        )
      "
      :okText="$t('remove')"
      persistent
      sm
      v-model="removeQuiz.status"
    >
      {{ $t("confirmQuizDeleteModalText") }}
    </Modal>
  </div>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import QuizModal from "../Quizes/QuizModal.vue";
import Alertbox from "../../mixins/AlertBox";
export default {
  mixins: [Alertbox],
  components: {
    Modal,
    QuizModal,
  },
  props: {
    percentOfCorrectAnswer: {
      default: 0,
    },
    quiz: {
      default: () => {},
    },
  },
  data() {
    return {
      showEditQuizModal: false,
      editModalKey: 1,
      selectedQuiz: null,
      removeQuiz: {
        status: false,
        id: -1,
        index: -1,
      },
    };
  },
  methods: {
    async SET_REMOVE_FILE(returnPath = "") {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.delete(`Quizzes/${this.removeQuiz.id}`);
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        if (returnPath === "") this.items.splice(this.removeQuiz.index, 1);
        else this.$router.push(returnPath);
        this.removeQuiz.status = false;
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
    },
    SHOW_QUIZ_DETAIL(item) {
      this.selectedQuiz = item;
      this.editModalKey += 1;
      this.showEditQuizModal = true;
    },
  },
};
</script>

<style></style>
