<template>
  <div class="container">
    <WhatsappConnection></WhatsappConnection>
  </div>
</template>

<script>
import WhatsappConnection from "../components/WhatsappConnection/index.vue";
export default {
  components: {
    WhatsappConnection,
  },
};
</script>

<style></style>
