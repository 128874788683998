<template>
  <div class="my-4 card">
    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-lg-4">
        <div class="form-group">
          <input
            type="text"
            :placeholder="$t('searchPlaceHolder')"
            id="search"
            v-model="search"
            class="form-control btn-pill mt-2"
          />
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="dropdown">
          <button
            class="btn btn-pill donate dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("filter") }}
          </button>
          <ul class="dropdown-menu">
            <li>
              <span
                @click="filterItem = ''"
                class="dropdown-item"
                :class="{ active: filterItem === '' }"
              >
                <i v-if="filterItem === ''">✓</i> {{ $t("showAll") }}</span
              >
            </li>
            <li>
              <span
                @click="filterItem = 1"
                class="dropdown-item"
                :class="{ active: filterItem === 1 }"
              >
                <i v-if="filterItem === 1">✓</i>
                {{ $t("onlyUplaodedItems") }}</span
              >
            </li>
            <li>
              <span
                @click="filterItem = 2"
                class="dropdown-item"
                :class="{ active: filterItem === 2 }"
              >
                <i v-if="filterItem === 2">✓</i>
                {{ $t("onlyDoesntUploadItems") }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <card
      :item="file"
      v-for="(file, i) in filterFiles"
      :key="cardKey + '-' + i"
    ></card>
  </div>
</template>

<script>
import Card from "./Card.vue";
export default {
  components: { Card },
  props: {
    files: {
      default: () => [],
    },
  },
  computed: {
    filterFiles() {
      return this.files.filter((file) => {
        if (this.filterItem === "") return this.SEARCH_FILES(file);
        if (this.filterItem === 1) return this.SEARCH_FILES_W_FILTER(file, 1);
        if (this.filterItem === 2) return this.SEARCH_FILES_W_FILTER(file, 2);
      });
    },
  },
  data() {
    return {
      filterItem: "",
      search: "",
      cardKey: 1,
    };
  },
  methods: {
    SEARCH_FILES(item) {
      this.cardKey += 1;

      return (
        (item.fullname?.toLowerCase() || "").includes(
          this.search.toLowerCase()
        ) ||
        (item.phone?.toLowerCase() || "").includes(this.search.toLowerCase()) ||
        (item.email?.toLowerCase() || "").includes(this.search.toLowerCase())
      );
    },
    SEARCH_FILES_W_FILTER(item, filter) {
      this.cardKey += 1;

      if (filter === 1)
        return (
          ((item.fullname?.toLowerCase() || "").includes(
            this.search.toLowerCase()
          ) ||
            (item.phone?.toLowerCase() || "").includes(
              this.search.toLowerCase()
            ) ||
            (item.email?.toLowerCase() || "").includes(
              this.search.toLowerCase()
            )) &&
          item.assignments.length > 0
        );
      else
        return (
          ((item.fullname?.toLowerCase() || "").includes(
            this.search.toLowerCase()
          ) ||
            (item.phone?.toLowerCase() || "").includes(
              this.search.toLowerCase()
            ) ||
            (item.email?.toLowerCase() || "").includes(
              this.search.toLowerCase()
            )) &&
          item.assignments.length === 0
        );
    },
  },
};
</script>

<style></style>
