<template>
  <div class="container mt-4">
    <div v-if="getCustomerListIsLoading">
      <PageLoading></PageLoading>
    </div>
    <div v-else>
      <h2>
        {{ $t("results") }}:
        <span class="text-muted">{{ getCustomer.fullname }}</span>
      </h2>
      <h6 class="mt-2">
        {{ $t("userInfo") }}: {{ getCustomer.phone }} - {{ getCustomer.email }}
      </h6>

      <h5 class="text-muted mt-4">
        {{ $t("quizResultsInfo", { count }) }}
      </h5>
      <div class="mt-5">
        <div v-if="isLoadingQuiz">
          <PageLoading></PageLoading>
        </div>
        <div v-else>
          <QuizDetailOfUserQuizHeader
            class="my-3"
            :class="i % 2 === 1 ? 'bg-light card' : ''"
            v-for="(item, i) in items"
            :key="i"
            :quiz="item"
            :percentOfCorrectAnswer="
              CALCULATE_PERCENT_OF_CORRECT_QUESTION(item)
            "
          ></QuizDetailOfUserQuizHeader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageLoading from "@/components/Shared/PageLoading.vue";
import QuizDetailOfUserQuizHeader from "./QuizDetailOfUserQuizHeader.vue";
import { formatter } from "gurhan/helpers/Date";

export default {
  components: {
    PageLoading,
    QuizDetailOfUserQuizHeader,
  },
  data() {
    return {
      id: 0,
      items: [],
      count: 0,
      page: 1,
      isLoadingQuiz: true,
    };
  },
  computed: {
    getCustomerListIsLoading() {
      return this.$store.state.CustomerList.customerListIsLoading;
    },
    getCustomer() {
      const customerList = this.$store.state.CustomerList.customerList.find(
        (p) => p.id === Number(this.id)
      );
      if (customerList) return customerList;
      else return { id: -1 };
    },
  },
  props: {
    quiz: {
      default: () => {},
    },
  },
  methods: {
    formatter,
    // GET CUSTOMER TO LIST
    GET_CUSTOMER_TO_LIST() {
      try {
        this.id = this.$route.query.userId;
        this.$store.dispatch("GET_CUSTOMER_TO_LIST", {
          id: Number(this.id),
        });
      } catch (error) {
        this.$router.push("/error");
      }
    },
    async GET_QUIZZES_OF_USER() {
      this.isLoadingQuiz = true;
      const response = await this.$api.get(
        `UserQuizzes/GetQuizUsersDetails?quizId=${this.quiz.id}&userId=${this.$route.query.userId}&page=${this.page}&limit=10`
      );
      if (response.message == "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else this.items = [];
      this.isLoadingQuiz = false;
    },
    CALCULATE_PERCENT_OF_CORRECT_QUESTION(quiz) {
      const total = quiz.correctAnswerTotalCount + quiz.wrongAnswerTotalCount;
      const percentOfCorrectAnswer =
        total == 0
          ? 0
          : Math.ceil((quiz.correctAnswerTotalCount * 100) / total);
      return percentOfCorrectAnswer;
    },
  },
  mounted() {
    this.GET_CUSTOMER_TO_LIST();
    this.GET_QUIZZES_OF_USER();
  },
};
</script>

<style></style>
