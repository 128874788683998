<template>
  <div>
    <UserAssignments></UserAssignments>
  </div>
</template>

<script>
import UserAssignments from "../components/UserAssignments/index.vue";
export default {
  components: { UserAssignments },
};
</script>

<style></style>
