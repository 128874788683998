<template>
  <section class="vid-title-sec">
    <!-- is active geldiğinde içeri gönder -->
    <div class="container">
      <form @submit.prevent="SET_MIDDLEWARE">
        <p v-if="!edit" class="text-danger">*: {{ $t("fieldIsRequired") }}.</p>
        <div class="mb-2 p-3 bg-light card" v-if="form.id > 0">
          <span v-if="form.isActive === 0">
            {{ $t("questionPublishMessage") }}</span
          >
          <span v-else> {{ $t("questionUnPublishMessage") }}</span>
          <div class="mt-2 text-right">
            <button
              v-if="form.isActive === 0"
              @click.prevent="UPDATE_ITEM(1)"
              class="btn subscribe btn-pill"
            >
              {{ $t("publish") }}
            </button>
            <button
              v-else
              @click.prevent="UPDATE_ITEM(0)"
              class="btn btn-danger btn-pill"
            >
              {{ $t("unpublish") }}
            </button>
          </div>
        </div>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("title") }} *</h2>
          <div class="form_field">
            <input
              type="text"
              required
              v-model="form.name"
              maxlength="100"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("description") }} *</h2>
          <div class="form_field">
            <textarea
              type="text"
              required
              v-model="form.description"
              maxlength="100"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <div class="vid-title">
          <h2 class="title-hd">{{ $t("duration") }} ({{ $t("min") }})*</h2>
          <div class="form_field">
            <input
              type="number"
              required
              v-model="form.duration"
              :min="1"
              :max="600"
              :placeholder="$t('addHereMax100Char')"
            />
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button @click.prevent="$emit('close')" class="btn btn-pill submit">
            {{ $t("close") }}
          </button>
          <button class="btn btn-pill donate ml-2">{{ $t("save") }}</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    modalRef: {
      default: () => {},
    },
    video: {
      default: () => {},
    },
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      fileArr: null,
      form: {
        id: -1,
        name: "",
        description: "",
        duration: null,
        isActive: 1,
        videoId: 0,
      },
    };
  },
  methods: {
    SET_MIDDLEWARE(e) {
      e.preventDefault();
      if (this.edit) this.UPDATE_ITEM(this.form.isActive);
      else this.SEND_TO_NEW_ITEM();
    },
    SET_EDIT_DATA(isActive = 1) {
      return {
        name: this.form.name,
        description: this.form.description,
        duration: this.form.duration,
        isActive,
      };
    },
    SET_DATA(isActive = 1) {
      return {
        name: this.form.name,
        description: this.form.description,
        duration: this.form.duration,
        videoId: this.video.id,
        isActive,
      };
    },
    async SEND_TO_NEW_ITEM() {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.post(`Quizzes`, this.SET_DATA());
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"));
        this.$emit("refresh", "success", this.$t("insertIsSucceed"), this.form);
      } else {
        this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
      }
      // this.SET_LOADING_MODAL(false);
    },
    async UPDATE_ITEM(isActive = 1) {
      this.SET_LOADING_MODAL(true);

      const response = await this.$api.put(
        `Quizzes/${this.form.id}`,
        this.SET_EDIT_DATA(isActive)
      );
      if (response.message === "OK" && response.result === "OK") {
        this.form.isActive = isActive;
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        this.$emit("refresh", "success", this.$t("updatedIsSucceed"), {
          ...this.form,
        });
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
      // this.SET_LOADING_MODAL(false);
    },
    SET_LOADING_MODAL(loading) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading,
        alertBox: {
          status: false,
          variant: "",
          message: "",
        },
      });
    },
    SET_ALERT_BOX(variant, message) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant,
          message,
        },
      });
    },
    SET_ITEM(e) {
      this.form.file = e.uri; // video file'ı
      this.fileArr = e.file; /// video linki
    },
  },
  created() {
    if (this.edit)
      this.form = {
        ...this.item,
      };
  },
};
</script>

<style></style>
