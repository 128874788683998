<template>
  <div class="card">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-12 col-lg-4">
          <p>{{ $t("startDate") }}</p>
          <h5>{{ formatter(new Date(quiz.startDate)) }}</h5>
          <p class="mt-2">{{ $t("finishDate") }}</p>
          <h5>{{ formatter(new Date(quiz.finishDate)) }}</h5>
          <p class="mt-2">{{ $t("situation") }}</p>
          <h5 :class="quiz.isFinish === 1 ? 'text-success' : 'text-danger'">
            {{ quiz.isFinish === 1 ? $t("done") : $t("undone") }}
          </h5>
        </div>
        <div class="col-6 col-lg-2">
          <div class="card mr-2">
            <div
              class="card-body d-flex align-items-center justify-content-center flex-column"
            >
              <h1>{{ quiz.questionTotalCount }}</h1>
              <div class="mt-1">
                <small class="text-muted">{{ $t("totalQuestion") }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <div class="card mr-2">
            <div
              class="card-body d-flex align-items-center justify-content-center flex-column"
            >
              <h1>{{ quiz.correctAnswerTotalCount }}</h1>
              <div class="mt-1">
                <small class="text-muted">{{ $t("correctQuestion") }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mt-2 mt-lg-0 col-lg-2">
          <div class="card mr-2">
            <div
              class="card-body d-flex align-items-center justify-content-center flex-column"
            >
              <h1
                :class="`${
                  percentOfCorrectAnswer >= 75
                    ? 'text-success'
                    : percentOfCorrectAnswer >= 50
                    ? 'text-warning'
                    : 'text-danger'
                }`"
              >
                {{ percentOfCorrectAnswer }}%
              </h1>
              <div class="mt-1">
                <small class="text-muted">{{
                  $t("percentOfCorrentAnswer")
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <button
            @click="SHOW_ANSWERS()"
            class="btn w-100 h-100 btn-text p-0 m-0"
          >
            <div class="card mr-2 bg-warning">
              <div
                class="card-body d-flex align-items-center justify-content-center flex-column"
              >
                <h1><i class="icon-search"></i></h1>
                <div class="mt-1">
                  <small class="text-muted">{{ $t("viewAnswers") }}</small>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="mt-4" v-if="showAnswers">
        <page-loading v-if="isLoading"></page-loading>
        <div class="row" v-else>
          <div class="col-12 col-lg-6">
            <h6>{{ $t("questions") }}</h6>
            <div
              class="card p-2 my-1"
              v-for="(question, i) in questionList"
              :key="i"
            >
              <button
                @click="activeQuestion = question"
                class="btn text-dark btn-text"
              >
                <div
                  class="d-flex justify-content-between align-items-center px-2"
                >
                  <span> {{ question.questionText }}</span>
                  <img src="/images/right-arrow.png" width="20" alt="" />
                </div>
              </button>
            </div>
          </div>
          <div v-if="activeQuestion !== null" class="col-12 col-lg-6">
            <h6>{{ $t("question") }}</h6>
            <div class="card p-3 my-1">
              <h6>{{ activeQuestion.questionText }}</h6>
            </div>
            <div class="mt-3">
              <h6>{{ $t("answers") }}</h6>
              <div
                class="card p-3 my-1"
                :class="{
                  'bg-success text-white':
                    answer.isAnswered === 1 &&
                    answer.quizQuestionAnswerId === 1,
                  'bg-danger text-white':
                    answer.isAnswered === 1 &&
                    answer.quizQuestionAnswerId !== 1,
                  'bg-success text-white':
                    answer.isAnswered === 0 &&
                    answer.quizQuestionAnswerId === 1,
                }"
                v-for="(answer, i) in activeQuestion.quizQuestionAnswers"
                :key="i"
              >
                {{ answer.answerText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import PageLoading from "../../Shared/PageLoading.vue";

export default {
  components: { PageLoading },
  props: {
    percentOfCorrectAnswer: {
      default: 0,
    },
    quiz: {
      default: () => {},
    },
  },
  data() {
    return {
      showAnswers: false,
      questionList: [],
      isLoading: false,
      activeQuestion: null,
    };
  },
  methods: {
    formatter,
    SHOW_ANSWERS() {
      this.showAnswers = true;
      if (this.questionList.length === 0)
        this.GET_QUESTION_LIST_OF_QUIZ_W_ANSWERS_OF_USER();
    },
    async GET_QUESTION_LIST_OF_QUIZ_W_ANSWERS_OF_USER() {
      this.isLoading = true;
      const response = await this.$api.get(
        `UserQuizzes/GetUserAnswers?userQuizId=${this.quiz.userQuizId}`
      );
      if (response.message === "OK") this.questionList = response.data.items;
      else this.questionList = [];
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
