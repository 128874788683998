<template>
  <div>
    <ModuleLocked v-if="!whatsappIntegrationModule" />
    <div v-else class="card p-4">
      <div class="text-center d-flex align-items-center justify-content-center">
        <img :src="getSettings.logo" width="60" alt="" />
        <img class="mx-4" src="/images/right-arrow.png" width="40" alt="" />
        <!-- <img src="/android-chrome-384x384" width="60" alt="" />
        <img class="mx-4" src="/images/right-arrow.png" width="40" alt="" /> -->
        <img src="/images/whatsapp.png" width="60" alt="" />
      </div>
      <div v-if="whatsAppStatus === 'LOADING' || isLoading">
        <h5 class="mt-4 text-center">
          {{ $t("whatsappChecking") }}
        </h5>
        <div class="mt-4 text-center">
          <loading></loading>
        </div>
      </div>
      <div v-else-if="whatsAppStatus === 'STOPPED'">
        <h5 class="mt-4 text-center">{{ $t("whatsappNotConnected") }}</h5>
        <p class="mt-3 text-center">
          {{ $t("whatsappConnectionDescription") }}
        </p>
        <div class="mt-4 text-center">
          <button
            :disabled="isLoading"
            @click="START_WHATSAPP()"
            class="btn btn-success"
          >
            {{ $t("startWhatsappConnection") }}
          </button>
        </div>
      </div>
      <div v-else-if="whatsAppStatus === 'STARTING'">
        <h5 class="mt-4 text-center">
          {{ $t("whatsappConnecting") }}
        </h5>
        <p class="text-center mt-2">
          {{ $t("creatingQrForWhatsapp") }}
        </p>
        <div class="mt-4 text-center">
          <loading></loading>
        </div>
      </div>
      <div class="mt-4 text-center" v-if="whatsAppStatus === 'SCAN_QR_CODE'">
        <h5>{{ $t("createdWhatsapp") }}</h5>
        <p class="my-3">
          {{ $t("createdWhatsappDescription") }}
        </p>
        <div class="my-3 text-right">
          <button
            :disabled="isLoading"
            @click="REFRESH_QR_CODE()"
            class="btn-default btn"
          >
            {{ $t("refreshToQr") }}
          </button>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-8 col-lg-8">
            <img :src="url" class="w-100" alt="" />
          </div>
        </div>
      </div>
      <div class="mt-4 text-center" v-if="whatsAppStatus === 'WORKING'">
        <h5>{{ $t("whatsappConnected") }}</h5>
        <p class="my-3">
          {{ $t("whatsappConnectedDescription") }}
        </p>
        <div class="my-3">
          <button
            :disabled="isLoading"
            @click="STOP_WHATSAPP()"
            class="btn-danger btn-pill btn"
          >
            {{ $t("closeToConnection") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../Shared/Loading.vue";
import ModuleLocked from "../Packages/ModuleLocked.vue";
import Modules from "../../mixins/Modules";
export default {
  mixins: [Modules],
  components: { Loading, ModuleLocked },
  data() {
    return {
      isLoading: false,
      whatsAppStatus: "LOADING",
      statusInterval: -1,
      scanQrCodeInterval: -1,
      url: "",
    };
  },
  computed: {
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  watch: {
    whatsappIntegrationModule(data) {
      if (data) this.GET_STATUS_OF_WHATSAPP();
    },
  },
  methods: {
    REFRESH_QR_CODE() {
      clearInterval(this.scanQrCodeInterval);
      this.scanQrCodeInterval = -1;
      this.GET_QR_CODE();
    },
    async GET_STATUS_OF_WHATSAPP(status = true) {
      if (!this.whatsappIntegrationModule) return;
      const response = await this.$api.post("Message/Status");
      if (response.status) {
        this.whatsAppStatus = response.status;
        if (response.status === "SCAN_QR_CODE" && status) this.GET_QR_CODE();
      }
      return response.status;
    },
    async START_WHATSAPP() {
      this.isLoading = true;
      const response = await this.$api.post("Message/Start");
      if (response.status) {
        this.whatsAppStatus = response.status;
        this.CHECK_STATUS_UNTIL_GET_QR_CODE();
      }
      this.isLoading = false;
      return response.status;
    },
    async STOP_WHATSAPP() {
      this.isLoading = true;
      const response = await this.$api.post("Message/Stop");
      if (response.status) {
        this.whatsAppStatus = response.status;
      } else {
        this.whatsAppStatus = "STOPPED";
      }
      this.isLoading = false;
      return response.status;
    },
    CHECK_STATUS_UNTIL_WORKING() {
      if (this.scanQrCodeInterval !== -1) return;
      const vm = this;
      this.scanQrCodeInterval = setInterval(async () => {
        console.log("bu interval mi çalışıyor");
        const response = await this.GET_STATUS_OF_WHATSAPP(false);
        if (response === "WORKING") {
          clearInterval(vm.scanQrCodeInterval);
          vm.scanQrCodeInterval = -1;
        }
      }, 3000);
    },
    CHECK_STATUS_UNTIL_GET_QR_CODE() {
      if (this.statusInterval !== -1) return;
      const vm = this;
      this.statusInterval = setInterval(async () => {
        const response = await this.GET_STATUS_OF_WHATSAPP();
        console.log(response);

        if (response === "SCAN_QR_CODE") {
          clearInterval(vm.statusInterval);
          vm.statusInterval = -1;
        }
      }, 3000);
    },
    async GET_QR_CODE() {
      this.isLoading = true;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${this.$api.token}`);

      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: myHeaders,
      };

      fetch(this.$api.BASE_URL + "Message/Screenshot", requestOptions)
        .then((response) => response.blob())
        .then((result) => {
          this.url = URL.createObjectURL(result);
          this.CHECK_STATUS_UNTIL_WORKING();
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.GET_STATUS_OF_WHATSAPP();
  },
};
</script>

<style></style>
