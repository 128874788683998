<template>
  <div class="container">
    <Tabs :fields="fields" :tab="tab" @change:tab="SET_TAB"></Tabs>
    <component :is="tab.path" :quiz="quiz"></component>
  </div>
</template>

<script>
import Questions from "./Questions.vue";
import Tabs from "./Tabs.vue";
import DoneQuizesByUsers from "./DoneQuizesByUsers.vue";
import QuizDetailOfUser from "./QuizDetailOfUser.vue";

export default {
  components: { Tabs, Questions, DoneQuizesByUsers, QuizDetailOfUser },
  props: {
    quiz: {
      default: () => {},
    },
  },
  watch: {
    "$route.query": {
      deep: true,
      handler() {
        this.GET_ACTIVE_TAB();
      },
    },
  },
  data() {
    return {
      tab: {},
      fields: [
        { title: this.$t("doneQuizesByUsers"), path: "DoneQuizesByUsers" },
        { title: this.$t("questions"), path: "Questions" },
        {
          title: this.$t("quizDetailOfUser"),
          path: "QuizDetailOfUser",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    SET_TAB(tab) {
      this.tab = tab;
      this.$router.push({ query: { ...this.$route.query, tab: tab.path } });
    },
    GET_ACTIVE_TAB() {
      const query = this.$route.query;
      this.tab =
        this.fields.find((field) => field.path === query.tab) || this.fields[0];
    },
  },
  created() {
    this.GET_ACTIVE_TAB();
  },
};
</script>

<style></style>
