var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('h3',[_vm._v(" "+_vm._s(_vm.quiz.quizInfo.name)+" "),_c('small',{staticClass:"badge",class:`badge-${
              _vm.quiz.quizInfo.isActive === 1 ? 'success' : 'danger'
            }`},[_vm._v(_vm._s(_vm.quiz.quizInfo.isActive === 1 ? _vm.$t("active") : _vm.$t("closed")))])]),_c('p',{staticClass:"text-muted mt-2"},[_vm._v(_vm._s(_vm.quiz.quizInfo.description))]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("duration"))+": "+_vm._s(_vm.quiz.quizInfo.duration)+"dk. ")]),_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"btn m-1 btn-pill btn-xs btn-warning mx-1",attrs:{"title":_vm.$t('update')},on:{"click":function($event){return _vm.SHOW_QUIZ_DETAIL(_vm.quiz.quizInfo)}}},[_c('img',{attrs:{"src":"/images/edit.svg","width":"20","alt":""}}),_vm._v(" "+_vm._s(_vm.$t("update"))+" ")]),_c('button',{staticClass:"btn m-1 btn-pill btn-xs btn-danger mx-1 btn-icon",attrs:{"title":_vm.$t('remove')},on:{"click":function($event){_vm.removeQuiz = { status: true, index: 0, id: _vm.quiz.quizInfo.id }}}},[_c('img',{attrs:{"src":"/images/trush.svg","width":"20","alt":""}}),_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")]),_vm._v(" - ")])]),_c('div',{staticClass:"col-6 col-lg-2"},[_c('div',{staticClass:"card mr-2"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('h1',[_vm._v(_vm._s(_vm.quiz.solverTotalCount))]),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("solverQuiz")))])])])])]),_c('div',{staticClass:"col-6 col-lg-2"},[_c('div',{staticClass:"card mr-2"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('h1',[_vm._v(_vm._s(_vm.quiz.solvedTotalCount))]),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("solvedQuiz")))])])])])]),_c('div',{staticClass:"col-12 mt-2 mt-lg-0 col-lg-2"},[_c('div',{staticClass:"card mr-2"},[_c('div',{staticClass:"card-body d-flex align-items-center justify-content-center flex-column"},[_c('h1',{class:`${
                _vm.percentOfCorrectAnswer >= 75
                  ? 'text-success'
                  : _vm.percentOfCorrectAnswer >= 50
                  ? 'text-warning'
                  : 'text-danger'
              }`},[_vm._v(" "+_vm._s(_vm.percentOfCorrectAnswer)+"% ")]),_c('div',{staticClass:"mt-1"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("percentOfCorrentAnswer")))])])])])])])]),_c('Modal',{attrs:{"hide-footer":""},model:{value:(_vm.showEditQuizModal),callback:function ($$v) {_vm.showEditQuizModal=$$v},expression:"showEditQuizModal"}},[_c('QuizModal',{key:_vm.editModalKey,attrs:{"product":_vm.product,"edit":"","event":_vm.event,"video":_vm.video,"type":_vm.fileType,"item":_vm.selectedQuiz},on:{"close":function($event){_vm.showEditQuizModal = false},"refresh":function($event){return _vm.$emit('refresh')}}})],1),_c('Modal',{attrs:{"okText":_vm.$t('remove'),"persistent":"","sm":""},on:{"ok":function($event){return _vm.SET_REMOVE_FILE(
        `/video-detail/${_vm.$route.params.sectionId}/${_vm.$route.params.videoId}?tab=Quizes`
      )}},model:{value:(_vm.removeQuiz.status),callback:function ($$v) {_vm.$set(_vm.removeQuiz, "status", $$v)},expression:"removeQuiz.status"}},[_vm._v(" "+_vm._s(_vm.$t("confirmQuizDeleteModalText"))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }