<template>
  <div>
    <PageLoading v-if="!loaded"></PageLoading>
    <div class="container" v-else>
      <HeaderCard
        @refresh="DECRYPTO_FILE"
        :assignment="assignment"
      ></HeaderCard>
      <Body :files="userAssignments"></Body>
    </div>
  </div>
</template>

<script>
import Crpyto from "gurhan/helpers/Crypto";
import PageLoading from "../Shared/PageLoading.vue";
import Body from "./Body.vue";
import HeaderCard from "./HeaderCard.vue";
const crypto = new Crpyto("KEY-OF-FILES");
export default {
  components: { PageLoading, HeaderCard, Body },
  data() {
    return {
      assignment: {},
      userAssignments: [],
      loaded: false,
    };
  },
  methods: {
    async DECRYPTO_FILE() {
      this.loaded = false;
      const hash = this.$route.query.hash;
      if (hash === null) return this.$router.push("/error");
      try {
        const assignment = JSON.parse(
          crypto.decrypto(hash.replace(/ADMIN/g, "+"))
        );
        //this.assignment = assignment;
        await this.GET_ASSIGNMENT(
          assignment.id,
          assignment.type,
          assignment.returnPath
        );
        this.GET_USER_ASSIGNMENTS();
      } catch (error) {
        console.log("error", error);
        this.$router.push("/error");
      }
    },
    async GET_ASSIGNMENT(id, type, returnPath) {
      const response = await this.$api.get(`Assignments/${id}`);
      if (response.message === "OK") {
        if (response.data === null) this.$router.push("/error");
        this.assignment = { ...response.data, type, returnPath };
        return true;
      } else this.$router.push("/error");
    },
    async GET_USER_ASSIGNMENTS(searchKey = "") {
      const response = await this.$api.get(
        `UserAssignments?assignmentId=${this.assignment.id}&searchKey=${searchKey}`
      );
      if (response.message === "OK") {
        this.userAssignments = response.data;
      } else this.userAssignments = [];
      this.loaded = true;
    },
  },
  mounted() {
    this.DECRYPTO_FILE();
  },
};
</script>

<style></style>
