<template>
  <section class="vid-title-sec">
    <!-- is active geldiğinde içeri gönder -->
    <div class="container">
      <form @submit.prevent>
        <div class="mb-2 p-3 bg-light card" v-if="form.id > 0">
          <span v-if="form.isActive === 0">
            {{ $t("questionPublishMessage") }}</span
          >
          <span v-else> {{ $t("questionUnPublishMessage") }}</span>
          <div class="mt-2 text-right">
            <button
              v-if="form.isActive === 0"
              @click="UPDATE_QUESTION(-1, 1)"
              class="btn subscribe btn-pill"
            >
              {{ $t("publish") }}
            </button>
            <button
              v-else
              @click="UPDATE_QUESTION(-1, 0)"
              class="btn btn-danger btn-pill"
            >
              {{ $t("unpublish") }}
            </button>
          </div>
        </div>
        <div class="mb-2 bg-light card">
          {{ $t("questionAnswerMessage2") }}
        </div>
        <div class="vid-title mt-2">
          <h2 class="title-hd">{{ $t("question") }} *</h2>
          <div class="form_field">
            <input
              type="text"
              required
              v-model="form.questionText"
              maxlength="600"
              :placeholder="$t('addHereN', { n: 600 })"
            />
            <div v-if="form.questionText.length === 0">
              <small class="text-danger">{{ $t("fieldIsRequired") }}</small>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button
            @click.prevent="
              form.id > -1 ? UPDATE_QUESTION() : SEND_NEW_QUESTION()
            "
            class="btn btn-pill donate ml-2"
          >
            {{ form.id > -1 ? $t("update") : $t("save") }}
          </button>
        </div>
        <div class="mt-5" v-if="form.id > -1">
          <div class="mb-2 alert alert-warning">
            {{ $t("questionAnswerMessage") }}
          </div>
          <div
            class="vid-title mt-2"
            v-for="(answer, i) in form.questionAnswers"
            :key="i"
          >
            <h2
              class="title-hd d-flex justify-content-between align-items-center"
            >
              <span>{{ i + 1 }}. {{ $t("option") }}</span>
              <div>
                <button
                  v-if="form.correctAnswerId !== answer.id"
                  class="btn btn-outline btn-sm mr-2"
                  @click.prevent="UPDATE_QUESTION(answer.id)"
                >
                  {{ $t("selectCorrectAnswer") }}
                </button>
                <button v-else class="btn btn-success text-white btn-sm mr-2">
                  {{ $t("correctAnswer") }}
                </button>
                <button
                  v-if="form.correctAnswerId !== answer.id"
                  @click.prevent="DELETE_ANSWER(answer, i)"
                  class="btn bg-danger btn-sm text-white"
                >
                  x
                </button>
              </div>
            </h2>
            <div class="form_field position-relative">
              <input
                type="text"
                :id="'answer-' + i"
                @blur.prevent="UPDATE_ANSWER(answer)"
                required
                v-model="answer.answerText"
                maxlength="400"
                :placeholder="$t('addHereN', { n: 400 })"
              />
              <div
                v-if="updateAnswerIsLoading.id === answer.id"
                class="saved-in-form_field"
              >
                <loading
                  v-if="updateAnswerIsLoading.text === 'loading'"
                ></loading>
                <div
                  v-else-if="updateAnswerIsLoading.text === 'saved'"
                  class="badge badge-success"
                >
                  {{ $t("saved") }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 text-center">
            <button
              @click.prevent="SEND_NEW_ANSWER()"
              class="btn btn-xs subsribe"
            >
              {{ $t("addNewOption") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import Loading from "../../Shared/Loading.vue";
export default {
  components: { Loading },
  props: {
    edit: {
      default: false,
      type: Boolean,
    },
    modalRef: {
      default: () => {},
    },
    quiz: {
      default: () => {},
    },
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      fileArr: null,
      updateAnswerIsLoading: {
        id: -1,
        text: "hidden",
      },
      form: {
        id: -1,
        questionText: "",
        questionAnswers: [],
        correctAnswerId: 1,
        quizId: 0,
        isActive: 1,
      },
    };
  },
  methods: {
    async DELETE_ANSWER(answer, i) {
      const response = await this.$api.delete(
        "QuizQuestions/QuizQuestionAnswers/" + answer.id
      );
      if (response.message === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        this.form.questionAnswers.splice(i, 1);
      } else this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
    },
    async SEND_NEW_QUESTION() {
      if (this.form.questionText.length === 0) return;
      const response = await this.$api.post("QuizQuestions", {
        questionText: this.form.questionText,
        quizId: this.quiz.id,
      });
      if (response.message === "OK") {
        this.form.id = response.data;
        this.SET_ALERT_BOX("success", this.$t("insertIsSucceed"));
        this.$emit("refresh");
      } else this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
    },
    async UPDATE_QUESTION(answerId = -1, isActive = 1) {
      const response = await this.$api.put("QuizQuestions/" + this.form.id, {
        questionText: this.form.questionText,
        correctAnswerId: answerId > -1 ? answerId : this.form.correctAnswerId,
        isActive,
      });
      if (response.message === "OK") {
        this.form.isActive = isActive;
        this.$emit("refresh");
        if (answerId > -1) this.form.correctAnswerId = answerId;
        else this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
      } else this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
    },
    async UPDATE_ANSWER(answer, isActive = 1) {
      this.updateAnswerIsLoading = {
        id: answer.id,
        text: "loading",
      };
      const response = await this.$api.put(
        "QuizQuestions/QuizQuestionAnswers/" + answer.id,
        {
          answerText: answer.answerText,
          isActive,
        }
      );
      if (response.message !== "OK")
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      this.updateAnswerIsLoading.text = "saved";
      setTimeout(() => {
        this.updateAnswerIsLoading = {
          id: -1,
          text: "hidden",
        };
      }, 2000);
    },
    async SEND_NEW_ANSWER() {
      const response = await this.$api.post(
        "QuizQuestions/QuizQuestionAnswers/Add",
        {
          quizQuestionId: this.form.id,
          answerText: "",
        }
      );
      if (response.message === "OK") {
        this.form.questionAnswers.push({
          id: response.data,
          answerText: "",
        });
        setTimeout(() => {
          document
            .getElementById("answer-" + this.form.questionAnswers.length - 1)
            .focus();
        }, 100);
      } else this.SET_ALERT_BOX("danger", this.$t("insertIsNotSucceed"));
    },
    SET_DATA() {
      return {
        questionText: this.form.questionText,
        quizId: this.quiz.id,
      };
    },
    SET_LOADING_MODAL(loading) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading,
        alertBox: {
          status: false,
          variant: "",
          message: "",
        },
      });
    },
    SET_ALERT_BOX(variant, message) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant,
          message,
        },
      });
    },
    SET_ITEM(e) {
      this.form.file = e.uri; // quiz file'ı
      this.fileArr = e.file; /// quiz linki
    },
  },
  created() {
    if (this.edit)
      this.form = {
        ...this.item,
      };
  },
};
</script>

<style>
.saved-in-form_field {
  position: absolute;
  right: 20px;
  top: 15px;
}
</style>
