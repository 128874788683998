<template>
  <QuizDetail></QuizDetail>
</template>

<script>
import QuizDetail from "../components/QuizDetail";
export default {
  components: { QuizDetail },
};
</script>

<style></style>
