<template>
  <div class="container">
    <h2 class="mt-4">
      {{ $t("doneQuizesByUsers") }} <span class="text-muted">{{ count }}</span>
    </h2>
    <Table
      :count="count"
      no-search
      :loading="loading"
      :items="items"
      :headers="headers"
    >
      <template #status="{ item }">
        <router-link
          :to="{ query: { tab: 'QuizDetailOfUser', userId: item.userId || 2 } }"
        >
          <button
            :title="$t('getResults')"
            class="btn m-1 btn-pill btn-xs btn-warning mx-1"
          >
            {{ item.id }} <i class="icon-search"></i> {{ $t("getResults") }}
          </button>
        </router-link>
      </template>
    </Table>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Modal
      hide-footer
      :title="$t('newQuestion')"
      v-model="showNewQuestionModal"
    >
      <QuestionModal
        :quiz="quiz"
        @refresh="REFRESH_COMPONENT"
        @close="showNewQuestionModal = false"
      ></QuestionModal>
    </Modal>
    <Modal hide-footer v-model="showEditQuestionModal">
      <QuestionModal
        edit
        :key="editModalKey"
        :quiz="quiz"
        :item="selectedQuestion"
        @close="showEditQuestionModal = false"
        @refresh="REFRESH_COMPONENT"
      ></QuestionModal>
    </Modal>
    <Modal
      @ok="SET_REMOVE_FILE()"
      :okText="$t('remove')"
      persistent
      sm
      v-model="removeQuestion.status"
    >
      {{ $t("confirmQuestionDeleteModalText") }}
    </Modal>
  </div>
</template>

<script>
import Table from "../../Tables/index.vue";
import DoneQuizesByUsersMixin from "./DoneQuizesByUsersMixin";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  mixins: [DoneQuizesByUsersMixin],
  components: { Table, BoostrapPagination },
  props: {
    quiz: {
      default: () => {},
    },
  },
  data() {
    return {
      removeQuestion: {
        status: false,
        id: -1,
        index: -1,
      },
      count: 0,
      page: 1,
      editModalKey: 1,
      selectedQuestion: {},
      showNewQuestionModal: false,
      showEditQuestionModal: false,
      confirmDeleteModal: false,
      items: [],
      headers: [
        {
          title: this.$t("name"),
          value: "fullname",
          col: 6,
          md: 5,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("phone"),
          value: "phone",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("email"),
          value: "email",
          col: 12,
          md: 3,
          lg: 4,
          xl: 4,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 3,
          lg: 6,
          xl: 6,
        },
      ],

      loading: false,
    };
  },
  computed: {
    fileType() {
      const typeOneArr = [1, 2, 4];
      const typeTwoArr = [3];
      const typeThreeArr = [5];
      if (typeOneArr.includes(this.type.typeTable)) return 1;
      else if (typeTwoArr.includes(this.type.typeTable)) return 2;
      else if (typeThreeArr.includes(this.type.typeTable)) return 3;
      else return 2;
    },
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  created() {
    this.GET_PAGE_FROM_QUERY();
  },
  mounted() {
    this.SET_FILE_TYPE_BEFORE_GET_USER_QUIZ();
  },
};
</script>

<style></style>
