<template>
  <div class="container">
    <div class="text-center" v-if="loading">
      <PageLoading></PageLoading>
    </div>
    <div v-else>
      <QuizHeader
        :percentOfCorrectAnswer="percentOfCorrectAnswer"
        :quiz="quiz"
        @refresh="GET_QUIZ_DETAIL()"
      ></QuizHeader>
      <div class="mt-4">
        <QuestionDetail :quiz="quiz.quizInfo"></QuestionDetail>
      </div>
    </div>
  </div>
</template>

<script>
import PageLoading from "../Shared/PageLoading.vue";
import QuizHeader from "./QuizHeader.vue";
import QuestionDetail from "./QuestionDetail/index.vue";
export default {
  components: { PageLoading, QuizHeader, QuestionDetail },
  data() {
    return {
      quiz: {
        correctQuestionTotalCount: 1,
        wrongQuestionTotalCount: 1,
      },
      percentOfCorrectAnswer: 0,
      loading: true,
    };
  },
  computed: {},
  methods: {
    async GET_QUIZ_DETAIL() {
      this.loading = true;
      const response = await this.$api.get(
        `Quizzes/Details/${this.$route.params.quizId}`
      );
      this.quiz = response.data;
      const total =
        this.quiz.correctQuestionTotalCount + this.quiz.wrongQuestionTotalCount;
      this.percentOfCorrectAnswer =
        total == 0
          ? 0
          : Math.ceil((this.quiz.correctQuestionTotalCount * 100) / total);
      this.loading = false;
    },
  },
  mounted() {
    this.GET_QUIZ_DETAIL();
  },
};
</script>

<style></style>
