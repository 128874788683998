<template>
  <div class="container">
    <h2 class="mt-4">
      {{ $t("questions") }} <span class="text-muted">{{ count }}</span>
    </h2>
    <div class="d-flex justify-content-end">
      <button
        @click="showNewQuestionModal = true"
        class="btn btn-pill donate my-3"
      >
        {{ $t("newQuestion") }}
      </button>
    </div>
    <Table
      :count="count"
      no-search
      :loading="loading"
      :items="items"
      :headers="headers"
    >
      <template #questionAnswers="{ item }">
        <span
          :class="`badge badge-${
            item.questionAnswers.length === 0 ? 'danger' : 'success'
          }`"
          >{{ item.questionAnswers.length }} {{ $t("option") }}</span
        >
      </template>
      <template #status="{ item, index }">
        <button
          :title="$t('update')"
          class="btn m-1 btn-pill btn-xs btn-warning mx-1"
          @click="SHOW_QUESTION_DETAIL(item)"
        >
          <img src="/images/edit.svg" width="20" alt="" />
        </button>
        <button
          :title="$t('remove')"
          @click="removeQuestion = { status: true, index, id: item.id }"
          class="btn m-1 btn-pill btn-xs btn-danger mx-1 btn-icon"
        >
          <img src="/images/trush.svg" width="20" alt="" />
        </button>
      </template>
      <template #questionText="{ item }">
        <strong
          class="text-danger"
          v-if="item.questionAnswers.length === 0"
          :title="$t('questionAnswersNotFound')"
          >?</strong
        >
        {{ item.questionText }}
        <small
          class="badge"
          :class="`badge-${item.isActive === 1 ? 'success' : 'danger'}`"
          >{{ item.isActive === 1 ? $t("active") : $t("closed") }}</small
        >
        <div>
          <small class="text-muted">
            {{ GET_CORRECT_ANSWER(item) }}
          </small>
        </div>
      </template>
      <template #createdAt="{ item }">
        {{ formatter(new Date(item.createdAt)) }}
      </template>
      <template #duration="{ item }">
        {{ item.duration }}{{ $t("min") }}
      </template>
    </Table>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Modal
      hide-footer
      :title="$t('newQuestion')"
      v-model="showNewQuestionModal"
    >
      <QuestionModal
        :quiz="quiz"
        @refresh="REFRESH_COMPONENT"
        @close="showNewQuestionModal = false"
      ></QuestionModal>
    </Modal>
    <Modal hide-footer v-model="showEditQuestionModal">
      <QuestionModal
        edit
        :key="editModalKey"
        :quiz="quiz"
        :item="selectedQuestion"
        @close="showEditQuestionModal = false"
        @refresh="REFRESH_COMPONENT"
      ></QuestionModal>
    </Modal>
    <Modal
      @ok="SET_REMOVE_FILE()"
      :okText="$t('remove')"
      persistent
      sm
      v-model="removeQuestion.status"
    >
      {{ $t("confirmQuestionDeleteModalText") }}
    </Modal>
  </div>
</template>

<script>
import Table from "../../Tables/index.vue";
import Questions from "./index";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  mixins: [Questions],
  components: { Table, BoostrapPagination },
  props: {
    quiz: {
      default: () => {},
    },
  },
  data() {
    return {
      removeQuestion: {
        status: false,
        id: -1,
        index: -1,
      },
      count: 0,
      page: 1,
      editModalKey: 1,
      selectedQuestion: {},
      showNewQuestionModal: false,
      showEditQuestionModal: false,
      confirmDeleteModal: false,
      items: [],
      headers: [
        {
          title: this.$t("question"),
          value: "questionText",
          col: 6,
          md: 5,
          lg: 5,
          xl: 5,
        },
        {
          title: this.$t("createdAt"),
          value: "createdAt",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("option"),
          value: "questionAnswers",
          col: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 3,
          lg: 6,
          xl: 6,
        },
      ],

      loading: false,
    };
  },
  computed: {
    fileType() {
      const typeOneArr = [1, 2, 4];
      const typeTwoArr = [3];
      const typeThreeArr = [5];
      if (typeOneArr.includes(this.type.typeTable)) return 1;
      else if (typeTwoArr.includes(this.type.typeTable)) return 2;
      else if (typeThreeArr.includes(this.type.typeTable)) return 3;
      else return 2;
    },
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  created() {
    this.GET_PAGE_FROM_QUERY();
  },
  mounted() {
    this.SET_FILE_TYPE_BEFORE_GET_QUESTION();
  },
};
</script>

<style></style>
